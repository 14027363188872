import { ReportHandler } from 'web-vitals'
import { isFunction } from 'lodash'

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (isFunction(onPerfEntry)) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry)
      getFID(onPerfEntry)
      getFCP(onPerfEntry)
      getLCP(onPerfEntry)
      getTTFB(onPerfEntry)
    })
  }
}

export default reportWebVitals
