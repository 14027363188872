import React, { createContext, ReactNode, SetStateAction, useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

export const ColorContext = createContext<boolean>(false)
export const ActionColorContext = createContext<React.Dispatch<SetStateAction<boolean>>>(() => false)

export const ThemeColorProvider = ({ children }: { children: ReactNode }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(false)

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkTheme ? 'dark' : 'light'
        },
        components: {
          MuiDialog: {
            styleOverrides: {
              paper: {
                borderRadius: '8px'
              }
            }
          }
        }
      }),
    [isDarkTheme]
  )

  return (
    <ActionColorContext.Provider value={setIsDarkTheme}>
      <ColorContext.Provider value={isDarkTheme}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </ColorContext.Provider>
    </ActionColorContext.Provider>
  )
}
