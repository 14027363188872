import { FallbackProps } from 'react-error-boundary'
import { Button, Typography } from '@mui/material'

export function ErrorPage ({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <>
      <Typography>Custom App Error - app crashed: `${error?.message || ''}`</Typography>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </>
  )
}
