import { createBrowserRouter } from 'react-router-dom'
import { routes } from './routes'
import { CustomErrorPage } from 'pages/error-pages/custom-error-page'
import { WithSuspense } from 'hoc/with-suspense'

export const routesConfig = createBrowserRouter([
  {
    path: routes.APP.path,
    element: WithSuspense(routes.APP.Component),
    errorElement: <CustomErrorPage />,
    children: [
      {
        path: routes.LOGIN.path,
        element: WithSuspense(routes.LOGIN.Component)
      },
      {
        path: routes.AUTH_FORGOTTEN.path,
        element: WithSuspense(routes.AUTH_FORGOTTEN.Component)
      },
      {
        path: routes.PAGE_NOT_FOUND.path,
        element: WithSuspense(routes.PAGE_NOT_FOUND.Component)
      },
      {
        path: routes.PASSWORD_CREATION.path,
        element: WithSuspense(routes.PASSWORD_CREATION.Component)
      },
      {
        path: routes.PASSWORD_RESET.path,
        element: WithSuspense(routes.PASSWORD_RESET.Component)
      },
      {
        path: routes.HOME.path,
        element: WithSuspense(routes.HOME.Component),
        children: [
          {
            path: routes.USER_PROFILE.path,
            element: WithSuspense(routes.USER_PROFILE.Component),
            loader: () => routes.USER_PROFILE.role
          },
          {
            path: routes.DASHBOARD.path,
            element: WithSuspense(routes.DASHBOARD.Component),
            loader: () => routes.DASHBOARD.role
          },
          {
            path: routes.STORAGE.path,
            element: WithSuspense(routes.STORAGE.Component),
            loader: () => routes.STORAGE.role,
            children: [
              {
                path: routes.STORAGE_CONTENT.path,
                element: WithSuspense(routes.STORAGE_CONTENT.Component),
                loader: () => routes.STORAGE_CONTENT.role
              }
            ]
          },
          {
            path: routes.PRODUCT_GROUP.path,
            element: WithSuspense(routes.PRODUCT_GROUP.Component),
            loader: () => routes.PRODUCT_GROUP.role
          },
          {
            path: routes.FSS_DOCUMENTS.path,
            element: WithSuspense(routes.FSS_DOCUMENTS.Component),
            loader: () => routes.FSS_DOCUMENTS.role
          },
          {
            path: routes.PZ_REGISTER.path,
            element: WithSuspense(routes.PZ_REGISTER.Component),
            loader: () => routes.PZ_REGISTER.role,
            children: [
              {
                path: routes.PZ_REGISTER_TABLE.path,
                element: WithSuspense(routes.PZ_REGISTER_TABLE.Component),
                loader: () => routes.PZ_REGISTER_TABLE.role
              },
              {
                path: routes.PZ_REGISTER_CREATE.path,
                element: WithSuspense(routes.PZ_REGISTER_CREATE.Component),
                loader: () => routes.PZ_REGISTER_CREATE.role
              },
              {
                path: routes.PZ_REGISTER_ID.path,
                element: WithSuspense(routes.PZ_REGISTER_ID.Component),
                loader: () => routes.PZ_REGISTER_ID.role
              }
            ]
          },
          {
            path: routes.TEMPLATES.path,
            element: WithSuspense(routes.TEMPLATES.Component),
            loader: () => routes.TEMPLATES.role,
            children: [
              {
                path: routes.TEMPLATES_TABLE.path,
                element: WithSuspense(routes.TEMPLATES_TABLE.Component),
                loader: () => routes.TEMPLATES_TABLE.role
              },
              {
                path: routes.TEMPLATES_CREATE.path,
                element: WithSuspense(routes.TEMPLATES_CREATE.Component),
                loader: () => routes.TEMPLATES_CREATE.role
              },
              {
                path: routes.TEMPLATES_ID.path,
                element: WithSuspense(routes.TEMPLATES_ID.Component),
                loader: () => routes.TEMPLATES_ID.role
              }
            ]
          },
          {
            path: routes.CLIENT_ORDER.path,
            element: WithSuspense(routes.CLIENT_ORDER.Component),
            loader: () => routes.CLIENT_ORDER.role,
            children: [
              {
                path: routes.CLIENT_ORDERS_LIST.path,
                element: WithSuspense(routes.CLIENT_ORDERS_LIST.Component),
                loader: () => routes.CLIENT_ORDERS_LIST.role
              },
              {
                path: routes.CLIENT_ORDER_CREATE.path,
                element: WithSuspense(routes.CLIENT_ORDER_CREATE.Component),
                loader: () => routes.CLIENT_ORDER_CREATE.role
              },
              {
                path: routes.CLIENT_ORDER_ID.path,
                element: WithSuspense(routes.CLIENT_ORDER_ID.Component),
                loader: () => routes.CLIENT_ORDER_ID.role
              }
            ]
          },
          {
            path: routes.COMPLAINT.path,
            element: WithSuspense(routes.COMPLAINT.Component),
            loader: () => routes.COMPLAINT.role
          },
          {
            path: routes.ORGANIZATIONS.path,
            element: WithSuspense(routes.ORGANIZATIONS.Component),
            loader: () => routes.ORGANIZATIONS.role,
            children: [
              {
                path: routes.ORGANIZATIONS_FORM.path,
                element: WithSuspense(routes.ORGANIZATIONS_FORM.Component),
                loader: () => routes.ORGANIZATIONS_FORM.role
              },
              {
                path: routes.ORGANIZATIONS_INFO.path,
                element: WithSuspense(routes.ORGANIZATIONS_INFO.Component),
                loader: () => routes.ORGANIZATIONS_INFO.role
              }
            ]
          },
          {
            path: routes.FSS_ADMIN_ORDERS.path,
            element: WithSuspense(routes.FSS_ADMIN_ORDERS.Component),
            loader: () => routes.FSS_ADMIN_ORDERS.role,
            children: [
              {
                path: routes.FSS_ADMIN_ORDERS_LIST.path,
                element: WithSuspense(routes.FSS_ADMIN_ORDERS_LIST.Component),
                loader: () => routes.FSS_ADMIN_ORDERS_LIST.role
              },
              {
                path: routes.FSS_ADMIN_ORDERS_CREATE.path,
                element: WithSuspense(routes.FSS_ADMIN_ORDERS_CREATE.Component),
                loader: () => routes.FSS_ADMIN_ORDERS_CREATE.role
              },
              {
                path: routes.FSS_ADMIN_ORDERS_CREATE.path,
                element: WithSuspense(routes.FSS_ADMIN_ORDERS_CREATE.Component),
                loader: () => routes.FSS_ADMIN_ORDERS_CREATE.role
              },
              {
                path: routes.FSS_ADMIN_ORDERS_ID.path,
                element: WithSuspense(routes.FSS_ADMIN_ORDERS_ID.Component),
                loader: () => routes.FSS_ADMIN_ORDERS_ID.role
              }
            ]
          },
          {
            path: routes.FSS_COMPLAINTS.path,
            element: WithSuspense(routes.FSS_COMPLAINTS.Component),
            loader: () => routes.FSS_COMPLAINTS.role
          },
          {
            path: routes.SHIPMENTS.path,
            element: WithSuspense(routes.SHIPMENTS.Component),
            loader: () => routes.SHIPMENTS.role
          },
          {
            path: routes.FSS_ADMIN_ORDERS.path,
            element: WithSuspense(routes.FSS_ADMIN_ORDERS.Component),
            loader: () => routes.FSS_ADMIN_ORDERS.role
          }
        ]
      }
    ]
  }
])
