import React from 'react'

export type UserRoleDetails = {
  name: string,
  id: number
}

export type UserRole = {
  [name: string]: UserRoleDetails
}

export const Roles: UserRole = {
  ADMIN: {
    name: 'ADMIN',
    id: 1
  },
  PUBLIC: {
    name: 'CLIENT',
    id: 2
  }
} as const

export type SingleRouteObject = {
  key: string
  title: string
  path: string
  Component: React.ComponentType
  role: UserRoleDetails[]
}

export type RouteObject = {
  [name in PATH_NAMES]: SingleRouteObject
}

export type PathNames = {
  [name: string]: {
    name: string,
    path: string,
    roles: UserRoleDetails[]
  }
}

type PATH_NAMES =
  | 'APP'
  | 'HOME'
  | 'LOGIN'
  | 'PASSWORD_CREATION'
  | 'PASSWORD_RESET'
  | 'PAGE_NOT_FOUND'
  | 'AUTH_FORGOTTEN'
  | 'USER_PROFILE'
  | 'DASHBOARD'
  | 'CLIENT_ORDER'
  | 'CLIENT_ORDERS_LIST'
  | 'CLIENT_ORDER_CREATE'
  | 'CLIENT_ORDER_ID'
  | 'COMPLAINT'
  | 'FSS_DOCUMENTS'
  | 'PZ_REGISTER'
  | 'PZ_REGISTER_TABLE'
  | 'PZ_REGISTER_CREATE'
  | 'PZ_REGISTER_ID'
  | 'STORAGE'
  | 'STORAGE_CONTENT'
  | 'PRODUCT_GROUP'
  | 'TEMPLATES'
  | 'TEMPLATES_TABLE'
  | 'TEMPLATES_CREATE'
  | 'TEMPLATES_ID'
  | 'ORGANIZATIONS'
  | 'ORGANIZATIONS_FORM'
  | 'ORGANIZATIONS_INFO'
  | 'FSS_ADMIN_ORDERS'
  | 'FSS_ADMIN_ORDERS_LIST'
  | 'FSS_ADMIN_ORDERS_CREATE'
  | 'FSS_ADMIN_ORDERS_ID'
  | 'SHIPMENTS'
  | 'FSS_COMPLAINTS'
