import React from 'react'
import { routesConfig } from 'routes/routes-config'
import { RouterProvider } from 'react-router-dom'
import CustomErrorBoundary from 'pages/error-pages/error-boundary'
import { ThemeColorProvider } from 'context/theme-color'

function App () {
  return (
    <ThemeColorProvider>
      <CustomErrorBoundary>
        <RouterProvider router={routesConfig} />
      </CustomErrorBoundary>
    </ThemeColorProvider>
  )
}

export default App
