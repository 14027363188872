import { useRouteError, useNavigate } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { pathNames } from 'routes/routes'
import styles from './style.module.scss'

export function CustomErrorPage () {
  const routeError = useRouteError()
  const navigate = useNavigate()
  const { HOME } = pathNames

  console.error('Route error: ', routeError)

  return <Box className={styles.errorPageWrapper}>
    <Box>
      <Box>
        <Typography variant="h5">App crashed - more details in console</Typography>
      </Box>
      <Box>
        <Button onClick={() => navigate(`${HOME.path}/dashboard`)}>Go back to home page</Button>
      </Box>
    </Box>
  </Box>
}
