import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from './error-page'

type ErrorBoundaryProps = {
  children: React.ReactNode
}

export default function CustomErrorBoundary ({ children }: ErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {children}
    </ErrorBoundary>
  )
}
