import React, { lazy } from 'react'
import {
  PathNames,
  Roles,
  UserRoleDetails,
  SingleRouteObject,
  RouteObject
} from './routes.model'

export const pathNames: PathNames = {
  APP: {
    name: 'App',
    path: '/',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  HOME: {
    name: 'Home',
    path: '/home',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  LOGIN: {
    name: 'Login',
    path: '/login',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  PASSWORD_CREATION: {
    name: 'Password creation',
    path: '/password/creation',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  PASSWORD_RESET: {
    name: 'Password reset',
    path: '/password/reset',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  PAGE_NOT_FOUND: {
    name: 'Not Found',
    path: '*',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  AUTH_FORGOTTEN: {
    name: 'Remind password',
    path: '/login/reminder',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  USER_PROFILE: {
    name: 'User profile',
    path: '/home/user/profile',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  DASHBOARD: {
    name: 'Dashboard',
    path: '/home/dashboard',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  CLIENT_ORDER: {
    name: 'Orders',
    path: '/home/client/orders',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  CLIENT_ORDERS_LIST: {
    name: 'Orders',
    path: '/home/client/orders',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  CLIENT_ORDER_CREATE: {
    name: 'Create order',
    path: '/home/client/orders/create',
    roles: [Roles.ADMIN]
  },
  CLIENT_ORDER_ID: {
    name: 'Edit order',
    path: '/home/client/orders/:id',
    roles: [Roles.ADMIN]
  },
  CLIENT_COMPLAINT: {
    name: 'Complaints',
    path: '/home/client/complaint',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  FSS_DOCUMENTS: {
    name: 'Documents',
    path: '/home/admin/documents',
    roles: [Roles.ADMIN]
  },
  FSS_REGISTER_PZ: {
    name: 'Invoices',
    path: '/home/admin/documents/register-pz',
    roles: [Roles.ADMIN]
  },
  FSS_REGISTER_PZ_TABLE: {
    name: 'Invoices',
    path: '/home/admin/documents/register-pz',
    roles: [Roles.ADMIN]
  },
  FSS_REGISTER_PZ_CREATE: {
    name: 'Create invoice',
    path: '/home/admin/documents/register-pz/create',
    roles: [Roles.ADMIN]
  },
  FSS_REGISTER_PZ_ID: {
    name: 'Invoice ID',
    path: '/home/admin/documents/register-pz/:id',
    roles: [Roles.ADMIN]
  },
  STORAGE: {
    name: 'Storage',
    path: '/home/storage',
    roles: [Roles.ADMIN]
  },
  STORAGE_CONTENT: {
    name: 'Storage',
    path: '/home/storage',
    roles: [Roles.ADMIN]
  },
  PRODUCT_GROUP: {
    name: 'Product group',
    path: '/home/product-group',
    roles: [Roles.ADMIN]
  },
  PRODUCT_CREATE: {
    name: 'Create product',
    path: '/home/storage/create',
    roles: [Roles.ADMIN]
  },
  TEMPLATES: {
    name: 'Templates',
    path: '/home/admin/template',
    roles: [Roles.ADMIN]
  },
  TEMPLATES_TABLE: {
    name: 'template',
    path: '/home/admin/template',
    roles: [Roles.ADMIN]
  },
  TEMPLATES_CREATE: {
    name: 'Create template',
    path: '/home/admin/template/create',
    roles: [Roles.ADMIN]
  },
  TEMPLATES_ID: {
    name: 'Edit template',
    path: '/home/admin/template/:id',
    roles: [Roles.ADMIN]
  },
  FSS_CLIENT: {
    name: 'Clients',
    path: '/home/admin/fss-clients',
    roles: [Roles.ADMIN]
  },
  FSS_CLIENT_FORM: {
    name: 'Organization form',
    path: '/home/admin/fss-clients',
    roles: [Roles.ADMIN]
  },
  FSS_CLIENT_ID: {
    name: 'Organization id',
    path: '/home/admin/fss-clients/:id',
    roles: [Roles.ADMIN]
  },
  FSS_ADMIN_ORDERS: {
    name: 'Client orders',
    path: '/home/admin/orders',
    roles: [Roles.ADMIN]
  },
  FSS_ADMIN_ORDERS_LIST: {
    name: 'Orders',
    path: '/home/admin/orders',
    roles: [Roles.ADMIN, Roles.PUBLIC]
  },
  FSS_ADMIN_ORDERS_CREATE: {
    name: 'Create order',
    path: '/home/admin/orders/create',
    roles: [Roles.ADMIN]
  },
  FSS_ADMIN_ORDERS_ID: {
    name: 'Edit order',
    path: '/home/admin/orders/:id',
    roles: [Roles.ADMIN]
  },
  FSS_COMPLAINTS: {
    name: 'Client complaints',
    path: '/home/admin/complaints',
    roles: [Roles.ADMIN]
  },
  FSS_SHIPMENTS: {
    name: 'Shipments',
    path: '/home/admin/shipments',
    roles: [Roles.ADMIN]
  }
}

const AppTemplate = lazy(() => import('templates/main.template'))
const Home = lazy(() => import('pages/home'))
const Login = lazy(() => import('pages/login'))
const PageNotFound = lazy(() => import('pages/error-pages/page-not-found'))
const AuthForgotten = lazy(() => import('pages/forgot-password'))
const PasswordCreation = lazy(() => import('components/password/password-create'))
const PasswordReset = lazy(() => import('components/password/password-reset'))
const UserProfile = lazy(() => import('pages/user-profile').then(module => ({ default: module.UserProfileModule })))
const Dashboard = lazy(() => import ('pages/dashboard').then(module => ({ default: module.DashboardModule })))
const TemplateCreate = lazy(() => import('containers/templates/create-template.container'))
const TemplatesTable = lazy(() => import('containers/templates/templates-table.container'))
const Templates = lazy(() => import('pages/templates').then(module => ({ default: module.Templates })))
const Product_Group = lazy(() => import('pages/product-categories').then(module => ({ default: module.CategoriesModule })))
const Complaint = lazy(() => import('pages/client-complaint').then(module => ({ default: module.Complaints })))
const Organizations = lazy(() => import('pages/fss-clients'))
const OrganizationsForm = lazy(() => import('pages/organizations').then(module => ({ default: module.OrganizationModule })))
const OrganizationsInfo = lazy(() => import('pages/clients').then(module => ({ default: module.UsersModule })))
const FssDocuments = lazy(() => import('pages/documents').then(module => ({ default: module.DocumentsModule })))
const RegisterPz = lazy(() => import('pages/documents/pz-create').then(module => ({ default: module.RegisterPzModule })))
const RegisterPzTable = lazy(() => import('containers/fss-documents/pz-table.container'))
const Storage = lazy(() => import('pages/storage').then(module => ({ default: module.Storage })))
const StorageContent = lazy(() => import('containers/storage/storage-content.container'))
const PzForm = lazy(() => import('containers/fss-documents/pz-form.container'))
const ClientOrders = lazy(() => import('pages/client-orders/client-orders').then(module => ({ default: module.ClientOrders })))
const OrdersTable = lazy(() => import('pages/client-orders/orders-table'))
const ClientOrdersCreate = lazy(() => import('pages/client-orders/create-order'))
const AdminClientOrders = lazy(() => import ('pages/fss-orders/admin-orders').then(module => ({ default: module.AdminOrders })))
const AdminOrdersTable = lazy(() => import ('pages/fss-orders/admin-orders-table'))
const AdminOrdersCreate = lazy(() => import('containers/fss-admin-orders/admin-order.container'))
const FssComplaints = lazy(() => import('pages/fss-complaint').then(module => ({ default: module.FssComplaintModule })))
const Shipments = lazy(() => import('pages/shipment').then(module => ({ default: module.ShipmentModule })))

function createRoute (key: string, title: string, path: string, role: UserRoleDetails[], Component: React.ComponentType): SingleRouteObject {
  return {
    key,
    title,
    path,
    Component,
    role
  }
}

export const routes: RouteObject = {
  APP: createRoute('app-wrapper', pathNames.APP.name, pathNames.APP.path, pathNames.APP.roles, AppTemplate),
  HOME: createRoute('home-route', pathNames.HOME.name, pathNames.HOME.path, pathNames.HOME.roles, Home),
  LOGIN: createRoute('login-route', pathNames.LOGIN.name, pathNames.LOGIN.path, pathNames.LOGIN.roles, Login),
  AUTH_FORGOTTEN: createRoute('login-forgotten', pathNames.AUTH_FORGOTTEN.name, pathNames.AUTH_FORGOTTEN.path, pathNames.AUTH_FORGOTTEN.roles, AuthForgotten),
  PASSWORD_CREATION: createRoute('password-creation', pathNames.PASSWORD_CREATION.name, pathNames.PASSWORD_CREATION.path, pathNames.PASSWORD_CREATION.roles, PasswordCreation),
  PASSWORD_RESET: createRoute('password-reset', pathNames.PASSWORD_RESET.name, pathNames.PASSWORD_RESET.path, pathNames.PASSWORD_RESET.roles, PasswordReset),
  PAGE_NOT_FOUND: createRoute('page-not-found-route', pathNames.PAGE_NOT_FOUND.name, pathNames.PAGE_NOT_FOUND.path, pathNames.PAGE_NOT_FOUND.roles, PageNotFound),
  USER_PROFILE: createRoute('user-profile-route', pathNames.USER_PROFILE.name, pathNames.USER_PROFILE.path, pathNames.USER_PROFILE.roles, UserProfile),
  DASHBOARD: createRoute('fss-dashboard', pathNames.DASHBOARD.name, pathNames.DASHBOARD.path, pathNames.DASHBOARD.roles, Dashboard),
  CLIENT_ORDER: createRoute('fss-client-orders', pathNames.CLIENT_ORDER.name, pathNames.CLIENT_ORDER.path, pathNames.CLIENT_ORDER.roles, ClientOrders),
  CLIENT_ORDERS_LIST: createRoute('fss-client-orders-list', pathNames.CLIENT_ORDERS_LIST.name, pathNames.CLIENT_ORDERS_LIST.path, pathNames.CLIENT_ORDERS_LIST.roles, OrdersTable),
  CLIENT_ORDER_CREATE: createRoute('fss-client-orders-client', pathNames.CLIENT_ORDER_CREATE.name, pathNames.CLIENT_ORDER_CREATE.path, pathNames.CLIENT_ORDER_CREATE.roles, ClientOrdersCreate),
  CLIENT_ORDER_ID: createRoute('fss-client-orders-id', pathNames.CLIENT_ORDER_ID.name, pathNames.CLIENT_ORDER_ID.path, pathNames.CLIENT_ORDER_ID.roles, ClientOrdersCreate),
  TEMPLATES: createRoute('production-templates', pathNames.TEMPLATES.name, pathNames.TEMPLATES.path, pathNames.TEMPLATES.roles, Templates),
  TEMPLATES_TABLE: createRoute('production-template', pathNames.TEMPLATES_TABLE.name, pathNames.TEMPLATES_TABLE.path, pathNames.TEMPLATES_TABLE.roles, TemplatesTable),
  TEMPLATES_CREATE: createRoute('production-template-create-route', pathNames.TEMPLATES_CREATE.name, pathNames.TEMPLATES_CREATE.path, pathNames.TEMPLATES_CREATE.roles, TemplateCreate),
  TEMPLATES_ID: createRoute('production-template-id', pathNames.TEMPLATES_ID.name, pathNames.TEMPLATES_ID.path, pathNames.TEMPLATES_ID.roles, TemplateCreate),
  PRODUCT_GROUP: createRoute('product-group-route', pathNames.PRODUCT_GROUP.name, pathNames.PRODUCT_GROUP.path, pathNames.PRODUCT_GROUP.roles, Product_Group),
  STORAGE: createRoute('storage-route', pathNames.STORAGE.name, pathNames.STORAGE.path, pathNames.STORAGE.roles, Storage),
  STORAGE_CONTENT: createRoute('storage-content-route', pathNames.STORAGE.name, pathNames.STORAGE.path, pathNames.STORAGE.roles, StorageContent),
  COMPLAINT: createRoute('client-complaint-key', pathNames.CLIENT_COMPLAINT.name, pathNames.CLIENT_COMPLAINT.path, pathNames.CLIENT_COMPLAINT.roles, Complaint),
  ORGANIZATIONS: createRoute('clients-route', pathNames.FSS_CLIENT.name, pathNames.FSS_CLIENT.path, pathNames.FSS_CLIENT.roles, Organizations),
  ORGANIZATIONS_FORM: createRoute('client-form-route', pathNames.FSS_CLIENT_FORM.name, pathNames.FSS_CLIENT_FORM.path, pathNames.FSS_CLIENT_FORM.roles, OrganizationsForm),
  ORGANIZATIONS_INFO: createRoute('client-id-route', pathNames.FSS_CLIENT_ID.name, pathNames.FSS_CLIENT_ID.path, pathNames.FSS_CLIENT_ID.roles, OrganizationsInfo),
  FSS_DOCUMENTS: createRoute('fss-storage-key', pathNames.FSS_DOCUMENTS.name, pathNames.FSS_DOCUMENTS.path, pathNames.FSS_DOCUMENTS.roles, FssDocuments),
  PZ_REGISTER: createRoute('fss-register-pz', pathNames.FSS_REGISTER_PZ.name, pathNames.FSS_REGISTER_PZ.path, pathNames.FSS_REGISTER_PZ.roles, RegisterPz),
  PZ_REGISTER_TABLE: createRoute('fss-register-pz-table', pathNames.FSS_REGISTER_PZ_TABLE.name, pathNames.FSS_REGISTER_PZ_TABLE.path, pathNames.FSS_REGISTER_PZ_TABLE.roles, RegisterPzTable),
  PZ_REGISTER_CREATE: createRoute('fss-register-pz-create', pathNames.FSS_REGISTER_PZ_CREATE.name, pathNames.FSS_REGISTER_PZ_CREATE.path, pathNames.FSS_REGISTER_PZ_CREATE.roles, PzForm),
  PZ_REGISTER_ID: createRoute('fss-register-pz-id', pathNames.FSS_REGISTER_PZ_ID.name, pathNames.FSS_REGISTER_PZ_ID.path, pathNames.FSS_REGISTER_PZ_ID.roles, PzForm),
  FSS_ADMIN_ORDERS: createRoute('fss-admin-orders', pathNames.FSS_ADMIN_ORDERS.name, pathNames.FSS_ADMIN_ORDERS.path, pathNames.FSS_ADMIN_ORDERS.roles, AdminClientOrders),
  FSS_ADMIN_ORDERS_LIST: createRoute('fss-admin-orders-list', pathNames.FSS_ADMIN_ORDERS_LIST.name, pathNames.FSS_ADMIN_ORDERS_LIST.path, pathNames.FSS_ADMIN_ORDERS_LIST.roles, AdminOrdersTable),
  FSS_ADMIN_ORDERS_CREATE: createRoute('fss-admin-orders-create', pathNames.FSS_ADMIN_ORDERS_CREATE.name, pathNames.FSS_ADMIN_ORDERS_CREATE.path, pathNames.FSS_ADMIN_ORDERS_CREATE.roles, AdminOrdersCreate),
  FSS_ADMIN_ORDERS_ID: createRoute('fss-admin-orders-create', pathNames.FSS_ADMIN_ORDERS_ID.name, pathNames.FSS_ADMIN_ORDERS_ID.path, pathNames.FSS_ADMIN_ORDERS_ID.roles, AdminOrdersCreate),
  FSS_COMPLAINTS: createRoute('fss-complaints_admin', pathNames.FSS_COMPLAINTS.name, pathNames.FSS_COMPLAINTS.path, pathNames.FSS_COMPLAINTS.roles, FssComplaints),
  SHIPMENTS: createRoute('fss-shipments', pathNames.FSS_SHIPMENTS.name, pathNames.FSS_SHIPMENTS.path, pathNames.FSS_SHIPMENTS.roles, Shipments)
}
